window.TrelloPowerUp.initialize({
  'board-buttons': function(t, options) {
    return [{
      icon: 'https://trello.com/assets/097751d33c1ec6d06bfc.png', // Replace with your icon
      text: 'Show Grid',
      callback: showGrid
    }];
  }
});

function showGrid(t) {
  // Fetch custom fields from the board
  return t.get('board', 'shared', 'customFields').then(function(customFields) {
    if (!customFields || customFields.length === 0) {
      return t.alert({
        message: 'No custom fields found on this board!'
      });
    }

    // Create an array of options for each custom field
    const customFieldOptions = customFields.map(field => {
      return {
        text: field.name,
        value: field.id
      };
    });

    // Create two dropdowns (selectors) for the user to choose two custom fields
    return t.popup({
      title: 'Select Custom Fields for Grid',
      items: [{
          text: 'Select First Axis',
          type: 'select',
          options: customFieldOptions,
          callback: (firstSelection) => {
            t.set('board', 'shared', 'firstSelectedField', firstSelection);

            // Second selector
            return t.popup({
              title: 'Select Second Axis',
              items: [{
                text: 'Select Second Axis',
                type: 'select',
                options: customFieldOptions,
                callback: (secondSelection) => {
                  t.set('board', 'shared', 'secondSelectedField', secondSelection);

                  // Display the grid with the selected custom fields
                  displayGrid(t, firstSelection, secondSelection);
                }
              }]
            });
          }
      }]
    });
  });
}

// Function to display the grid based on the selected custom fields
function displayGrid(t, firstSelectedField, secondSelectedField) {
  t.board().then((board) => {
    const firstField = board.customFields.find(field => field.id === firstSelectedField);
    const secondField = board.customFields.find(field => field.id === secondSelectedField);
    
    // Implement your logic to display the tickets on a grid based on selected fields
    alert(`Grid displayed for ${firstField.name} and ${secondField.name}`);
  });
}
